import { createIntl, createIntlCache } from "@formatjs/intl";
import { LANGUAGE_FALLBACK } from "@swan-io/shared-business/src/utils/languages";
import { ReactElement, ReactNode, cloneElement, isValidElement } from "react";
import translationEN from "../locales/en.json";

export type TranslationKey = keyof typeof translationEN;

const intl = createIntl(
  {
    defaultLocale: LANGUAGE_FALLBACK,
    fallbackOnEmptyString: false,
    locale: LANGUAGE_FALLBACK,
    messages: translationEN,
  },
  createIntlCache(),
);

export const t = (key: TranslationKey, params?: Record<string, string | number>) =>
  intl.formatMessage({ id: key, defaultMessage: translationEN[key] }, params);

export const formatNestedMessage = (
  key: TranslationKey,
  params: Record<string, string | number | ReactElement | ((children: ReactNode) => ReactNode)>,
) => {
  const result = intl.formatMessage(
    { id: key, defaultMessage: translationEN[key] },
    // @ts-expect-error
    params,
  );

  const resultArray: (string | ReactElement)[] = typeof result === "string" ? [result] : result;

  return resultArray.map((item, index) =>
    isValidElement(item) ? cloneElement(item, { key: `t-${key}-${index}` }) : item,
  );
};
